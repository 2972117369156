import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  // useColorModeValue,
  // useBreakpointValue,
  DarkMode,
  useDisclosure,
  Container,
} from "@chakra-ui/react";
import { Link as RLink } from "react-router-dom";
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  ExternalLinkIcon,
} from "@chakra-ui/icons";
import NavbarLogo from "./NavBarLogo";
import { useLocale } from "locales";

// TODO: refactor use of url() functions with a LocaleLink component that extends react-router's Link
export default function WithSubnavigation() {
  const { tr, url } = useLocale();
  const { isOpen, onToggle } = useDisclosure();

  return (
    <DarkMode>
      <Box bg={"red.800"}>
        <Container maxW="container.xl">
          <Flex
            color={"white"}
            minH={"60px"}
            py={{ base: 3 }}
            px={{ base: 0 }}
            borderBottom={1}
            borderStyle={"solid"}
            borderColor={"red.900"}
            align="center"
          >
            <Flex
              flex={{ base: 1, md: "auto" }}
              ml={{ base: -2 }}
              display={{ base: "flex", md: "none" }}
            >
              <IconButton
                onClick={onToggle}
                icon={
                  isOpen ? (
                    <CloseIcon w={3} h={3} />
                  ) : (
                    <HamburgerIcon w={5} h={5} />
                  )
                }
                variant={"ghost"}
                aria-label={"Toggle Navigation"}
              />
            </Flex>
            <Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }}>
              <NavbarLogo />
              <Flex
                display={{ base: "none", md: "flex" }}
                ml={10}
                alignItems="center"
              >
                <DesktopNav />
              </Flex>
            </Flex>

            <Stack
              flex={{ base: 1, md: 0 }}
              justify={"flex-end"}
              direction={"row"}
              spacing={6}
            >
              <Button
                as={RLink}
                to={url("contact")}
                display={{ base: "none", md: "inline-flex" }}
                fontSize={"sm"}
                fontWeight={600}
                color={"black"}
                bg={"red.200"}
                _hover={{
                  bg: "red.400",
                }}
              >
                {tr("nav.contact")}
              </Button>
            </Stack>
          </Flex>

          <Collapse in={isOpen} animateOpacity>
            <MobileNav />
          </Collapse>
        </Container>
      </Box>
    </DarkMode>
  );
}
function as(navItem) {
  if (navItem.to) {
    return RLink;
  } else if (navItem.href) {
    return "a";
  } else {
    return "span";
  }
}
function isLink(navItem) {
  return !!(navItem.to || navItem.href);
}

const DesktopNav = () => {
  const navItems = useNavItems();
  const linkColor = "gray.200";
  const linkHoverColor = "white";
  const popoverContentBgColor = "green.700";

  return (
    <Stack direction={"row"} spacing={4}>
      {navItems.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              <Link
                as={as(navItem)}
                p={2}
                cursor={isLink(navItem) ? "pointer" : "default"}
                href={navItem.href}
                to={navItem.to}
                isExternal={navItem.isExternal}
                fontSize={"md"}
                fontWeight={500}
                color={linkColor}
                _hover={{
                  textDecoration: "none",
                  color: linkHoverColor,
                }}
              >
                {navItem.label}{" "}
                {navItem.isExternal && <ExternalLinkIcon mx="2px" />}
              </Link>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={"xl"}
                bg={popoverContentBgColor}
                p={4}
                rounded={"xl"}
                minW={"md"}
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, to, subLabel }) => {
  return (
    <Link
      as={RLink}
      href={href}
      to={to ? to : ""}
      role={"group"}
      display={"block"}
      p={2}
      rounded={"md"}
      _hover={{ bg: "green.600" }}
    >
      <Stack direction={"row"} align={"center"}>
        <Box>
          <Text
            transition={"all .0s ease"}
            _groupHover={{ color: "green.200" }}
            fontWeight={500}
          >
            {label}
          </Text>
          <Text fontSize={"sm"}>{subLabel}</Text>
        </Box>
        <Flex
          transition={"all .0s ease"}
          transform={"translateX(-10px)"}
          opacity={0}
          _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
          justify={"flex-end"}
          align={"center"}
          flex={1}
        >
          <Icon color={"green.200"} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
};

const MobileNav = () => {
  const navItems = useNavItems();
  return (
    <Stack bg={"green.800"} p={4} display={{ md: "none" }}>
      {navItems.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = (navItem) => {
  const { isOpen, onToggle } = useDisclosure();
  const { label, children, href, to } = navItem;

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={as(navItem)}
        href={href}
        to={to}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text fontWeight={600} color={"gray.200"}>
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={"gray.700"}
          color="white"
          align={"start"}
        >
          {children &&
            children.map((child) => (
              <Link
                as={as(child)}
                key={child.label}
                py={2}
                href={child.href}
                to={child.to}
              >
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

function useNavItems() {
  const { tr, url } = useLocale();
  const navItems = [
    {
      label: tr("nav.programs"),
      // to: url("learn"),
      children: [
        {
          label: tr("nav.piano.label"),
          subLabel: tr("nav.piano.sublabel"),
          to: url("programs/piano"),
        },
        {
          label: tr("nav.guitar.label"),
          subLabel: tr("nav.guitar.sublabel"),
          to: url("programs/guitar"),
        },
        {
          label: tr("nav.initiation.label"),
          subLabel: tr("nav.initiation.sublabel"),
          to: url("programs/initiation"),
        },
        /*
        {
          label: tr("nav.singingAndPercussion.label"),
          subLabel: tr("nav.singingAndPercussion.sublabel"),
          to: url("programs/signing-and-percussion"),
        },
        */
      ],
    },
    /*
    {
      label: tr("nav.foundation"),
      // to: url("learn"),
      children: [
        {
          label: tr("nav.about.label"),
          subLabel: tr("nav.about.sublabel"),
          to: url("foundation/about"),
        },
        {
          label: tr("nav.team.label"),
          subLabel: tr("nav.team.sublabel"),
          to: url("foundation/team"),
        },
      ],
    },
    {
      label: tr("nav.programs"),
      to: url("programs"),
    },
    {
      label: tr("nav.multimedia"),
      to: url("multimedia"),
    },
    {
      label: tr("nav.programs"),
      to: url("programs"),
    },
    */
    {
      label: tr("nav.professors"),
      to: url("professors"),
    },
    {
      label: tr("nav.contact"),
      to: url("contact"),
    },
  ];
  return navItems;
}
