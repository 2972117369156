import { isDev } from "../helpers/env";

export const EN = "en";
export const FR = "fr";
export const TR = "tr";
export const ES = "es";
export const UNKNOWN = "unknown";

export const GLOBAL = {
  code: "global",
  baseUrl: "croconotes.com",
  langs: [ES, EN, FR],
};

export const REGIONS = [GLOBAL];

// HACK: overwrite baseUrls in localhost development to avoid setting hosts file:
if (isDev()) {
  REGIONS.forEach((region) => {
    const suffix = region.code;
    region.baseUrl = `localhost:3000/${suffix}`;
  });
}

export const DEFAULT_REGION = GLOBAL;

export const GLOBAL_ES = { region: GLOBAL, lang: ES };
export const DEFAULT_LOCALE = GLOBAL_ES;
