import { useLocale } from "locales";
import React from "react";
import { Routes, Route } from "react-router-dom";

// import ComingSoon from "components/ComingSoon";
import Error404 from "./pages/Error404";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import ComingSoon from "./pages/ComingSoon";
import Professors from "./pages/Professors";
import * as foundation from "./pages/Foundation";
import * as programs from "./pages/Programs";

export default function CommonRoutes() {
  const locale = useLocale();
  const basePath = locale.baseURL.pathname;
  return (
    <Routes>
      <Route path={basePath}>
        <Route path="" element={<Home />} />
        <Route path="foundation">
          <Route path="team" element={<foundation.Team />} />
          <Route path="about" element={<foundation.About />} />
        </Route>
        <Route path="programs">
          <Route path="piano" element={<programs.Piano />} />
          <Route path="guitar" element={<programs.Guitar />} />
          <Route path="initiation" element={<programs.MusicalInitiation />} />
          <Route path="signing-and-percussion" element={<programs.SingingAndPercussion />} />
        </Route>
        <Route path="professors" element={<Professors />} />
        <Route path="contact" element={<Contact />} />
        <Route path="*" element={<Error404 />} />
      </Route>
      {/* Unknown locale... */}
      <Route path="*" element={<Error404 unknownLocale />} />
    </Routes>
  );
}
