import React from "react";
import NavBar from "components/NavBar";
import Footer from "components/Footer";
import { Box, VStack } from "@chakra-ui/react";

export default function Layout({ children }) {
  return (
    <VStack align="stretch" minHeight="100vh">
      <NavBar />
      <Box bgColor="white" color="black" marginTop="0px !important">
        {children}
      </Box>
      <Box marginTop="0px !important" flexGrow={1} bg="green.800">
        <Footer />
      </Box>
    </VStack>
  );
}
