import React from "react";
import Layout from "../../Layout";
import { Box, Container, Heading, Stack, Text, Image, VStack, HStack, StackDivider } from "@chakra-ui/react";
import { useLocale } from "locales";

export default function SingingAndPercussion() {
  const { tr } = useLocale();
  return (
    <Layout>
      <Container maxW="container.xl">
        <Box p={{ base: 8 }}>
          <Stack spacing={6}>
            <Heading size="3xl" color="green.800">
              {tr("pages.singingAndPercussion.header")}
            </Heading>
            <Heading size="2xl">
              {tr("pages.singingAndPercussion.section1.header")}
            </Heading>
            <Text>
              {tr("pages.singingAndPercussion.section1.text")}
            </Text>
            <Heading size="2xl">
              {tr("pages.singingAndPercussion.section2.header")}
            </Heading>
            <Text>
              {tr("pages.singingAndPercussion.section2.text")}
            </Text>
              {/*
                <Text>
                  <strong>{tr("registrationNotice")}</strong>
                </Text> 
              */}
          </Stack>
        </Box>
      </Container>
    </Layout>
  );
}
