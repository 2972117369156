import React from "react";

import { Button, Flex, Heading, Image, Stack, Text } from "@chakra-ui/react";
import { Link as RLink } from "react-router-dom";
import { useLocale } from "locales";

import logoImg from "../../../assets/logo.webp";

function photoUrl(path) {
  return `${process.env.PUBLIC_URL}/photos/${path}`;
}

export default function HeroSection() {
  const { tr, url } = useLocale();
  let heroImageUrl = photoUrl("photo1.jpg");

  return (
    <Stack minH={"30vh"} direction={{ base: "column", md: "row" }}>
      <Flex flex={1} align={"center"} justify={"center"} paddingY={4}>
        <Stack spacing={6} w={"full"} maxW={"lg"}>
          <Heading fontSize={{ base: "3xl", md: "4xl", lg: "5xl" }}>

        <Image
          alt={"Croco-Notes"}
          objectFit={"cover"}
          src={logoImg}
        />
            {/*
            <Text color={"palette.blue.3"} as={"span"}>
              {tr("hero.title")}{" "}
            </Text>{" "}
            */}
          </Heading>
          <Text fontSize={{ base: "md", lg: "lg" }} color={"gray.500"}>
            {tr("hero.subtitle")}
          </Text>
          <Stack direction={{ base: "column", md: "row" }} spacing={4}>
            <Button
              as={RLink}
              to={url("contact")}
              bg={"red.200"}
              color={"black"}
              _hover={{
                bg: "red.400",
              }}
            >
              {tr("nav.contact")}
            </Button>
            {/*
            <Button as={RLink} to={url("foundation/about")}>
              {tr("howFundsUsed")}
            </Button>
            */}
          </Stack>
        </Stack>
      </Flex>
      <Flex flex={1}>
        <Image
          alt={"Croco-Notes"}
          objectFit={"cover"}
          src={heroImageUrl}
        />
      </Flex>
    </Stack>
  );
}
